.avatar-large {
  height: 9rem;
  width: 9rem;
}

.avatar-size_3 {
  height: 3rem;
  width: 3rem;
}

.avatar-xxs {
  height: 1rem;
  width: 1rem;
}
.block {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.controlsBlock {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  flex-wrap: wrap;
}
.sidebar-drawer {
  width: $sidebar-width;

  .offcanvas-body {
    padding: 0;
  }

  .sidebar-drawer-header {
    height: $header-height;

    @media (max-width: 380px) {
      .navbar-brand-box {
        display: block;
      }
    }
  }

  .sidebar-drawer-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: calc(100vh - #{$header-height});
  }
}
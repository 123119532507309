@import "src/assets/scss/variables.module";

.button {
  display: flex;
  color: $black;
  text-transform: capitalize;
  padding: 0.25rem 0.5rem 0.25rem 0;

  outline: none;
  border: 0;
  background-color: transparent;

  &:hover {
    color: $primary;
  }

  &_active {
    color: $primary;
  }

  &_menu {
    font-size: 18px;
    align-items: center;
    padding: 0.25rem 0.5rem 0.25rem 1.5rem;
  }

  .icon {
    display: flex;
    width: 40px;
    height: 40px;
    margin-right: 0.5rem;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    //padding-top:  4,
  }

  .body {
    flex: 1;
  }

  .label {

  }

  .rightSection {
    margin-left: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.children {
  padding-left: calc(40px + 0.5rem + 1.5rem)
}
.block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.header {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 0.5rem;
}

.titleBlock {
  max-width: 500px;
}

.subHeader {
  font-size: 14px;
}
@import "../../../../../../assets/scss/variables.module";

.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  //height: calc(100vh - #{$header-height});
}

.navBlock {
  margin: 0.5rem 0;

  &__title {
    padding: 0.25rem 1.5rem 0.25rem 1.5rem;
    text-transform: capitalize;
    color: $secondary;
  }

  &__menu {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
}
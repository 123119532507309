.root {

}

.topBar {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;

  @media (max-width: 767.98px) {
    flex-direction: column;
  }
}

.titleBlock {
  flex: 1;
}
// Input - right accessory
.form-control.form-control-right-accessory {
  padding-right: 2.75rem;
  background-position: right 2.75rem center;
}

.was-validated .form-control:invalid,
.form-control.form-control-right-accessory.is-invalid {
  padding-right: $form-select-feedback-icon-padding-end;
  background-position: right 2.75rem center;
}

// Input - left accessory
.form-control.form-control-left-accessory {
  padding-left: 2rem;
}

.was-validated .form-control:invalid,
.form-control.form-control-left-accessory.is-invalid {
  //padding-left: $form-select-feedback-icon-padding-end;
}

.form-right-accessory {
  position: absolute;
  top: 0;
  right: 0.5rem;
  z-index: 3;
  height: 100%;
  display: flex;
  align-items: center;
}

.form-left-accessory {
  position: absolute;
  top: 0;
  left: 0.5rem;
  z-index: 4;
  height: 100%;
  display: flex;
  align-items: center;
}

.form-control-eye {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
}

.form-control-border-right-round {
  border-top-right-radius: $border-radius !important;
  border-bottom-right-radius: $border-radius !important;
}

.form-control-border-left-round {
  border-top-left-radius: $border-radius !important;
  border-bottom-left-radius: $border-radius !important;
}

.form-action-button-block {
  margin-top: calc(0.5rem + 24px);
  display: flex;
  height: 41px;
  align-items: center;

  @media (max-width: 767.98px) {
    margin-top: calc(0.5rem + 24px);
    height: 41px;
  }
}

// React Select
.select2-container {
  .input-group > div {
    width: 100%;
  }

  .select2-selection {
    &__control {
      min-height: calc(1.5rem + 0.5rem + 8px) !important;
      border-radius: $border-radius;

      // hotfix
      @media (max-width: 767.98px) {
        min-height: calc(1.5rem + 0.5rem + 9px) !important;
      }
    }

    &__value-container {
    }

    &__indicator {
      padding: 0 8px !important;
    }

    &__menu {
      z-index: 5;
    }
  }

  .is-invalid .select2-selection__control {
    border-color: $red !important;
  }
}

// Checkbox
.form-checkbox-inline {
  display: inline-block;

  .form-check {
    min-height: auto;
    margin: 0;
  }
}

.form-checkbox-control {
  margin-top: calc(0.5rem + 24px);
  display: flex;
  height: 40px;
  align-items: center;

  //@media (max-width: 767.98px) {
  //  margin-top: calc(0.5rem + 24px);
  //  height: 40px;
  //}
}
@import "src/assets/scss/variables.module";

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 0.75rem;
}

.table th {
  font-weight: 300;
  font-size: 14px;
}

.table th {
  padding: 0 0.75rem;
  white-space: nowrap;
}

.table td {
  padding: 0.75rem;
}

.table tbody tr {
  background-color: $card-bg;
  border-radius: $card-border-radius;
}

.table tbody tr td:first-child {
  border-top-left-radius: $card-border-radius;
  border-bottom-left-radius: $card-border-radius;
}

.table tbody tr td:last-child {
  border-top-right-radius: $card-border-radius;
  border-bottom-right-radius: $card-border-radius;
}